/* You can add global styles to this file, and also import other style files */
.po-page {
    background-color: #FFFFFF !important;
}

.po-page-header {
    padding: 16px 16px 0px 16px !important;
}

.po-page-content {
    padding: 0px 16px 16px 16px !important;
}

.insights-widget > .po-widget > po-container > .po-container > .po-widget-body {
    display: flex;
    height: 100%;
    align-items: center;
}

.set-up-modal > .po-modal > .po-modal-overlay > .po-modal-container > .po-modal-vertical-align > .po-modal-content > .po-modal-footer {
    visibility: hidden;
    padding: 10px;
    height: 0px;
}

.action-buttons > .buttons-modal > .button-modal > .po-button {
    min-width: 140px;
}

.action-buttons > .buttons-modal > .button-modal > .po-button:focus {
    outline: none;
    box-shadow: none;
}

.button-close > .button-modal > .po-button {
    min-width: 120px;
}

.button-close > .button-modal > .po-button:focus {
    outline: none;
    box-shadow: none;
}

.card-container > .po-container {
    padding: 0px;
    border: solid 1px #e0e0e0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.po-wrapper .po-page {
    margin-left: 0px !important;
    width: 100% !important;
}

.widget-heigth > .po-stepper > .po-stepper-content > po-step > app-validate-ini > po-widget > .po-widget > po-container > .po-container {
  height: 500px  !important;
}
